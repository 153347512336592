import React, { useContext, useEffect } from "react";
import PageHeader from "../components/PageHeader";
import { ThemeContext } from "../layout/Layout";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { InView, useInView } from "react-intersection-observer";
import adeelImg from "../images/adeel.jpeg";
import ringsImg from "../images/rings.png";
import Marquee from "../components/Marquee";

export default function About() {
  const theme = useContext(ThemeContext);
  const [adeelRef, adeelInView, adeellEntry] = useInView({ threshold: 0.25 });
  const [bossRef, bossInView, bossEntry] = useInView({ threshold: 0.25 });

  useEffect(() => {
    if (adeelInView) {
      gsap.to(".adeel", {
        duration: 0.4,
        opacity: 1,
        ease: "power4.ease",
      });
    }
    if (bossInView) {
      gsap.to(".boss", {
        duration: 0.4,
        delay: 2,
        opacity: 1,
        ease: "power4.ease",
      });
    }
  }, [adeelInView, bossInView]);

  return (
    <>
      <div className="w-screen relative z-20 min-h-screen flex flex-col items-start justify-center">
        <PageHeader title="About Us" />
        <div className="w-screen relative px-8 flex flex-col items-start justify-center h-screen ">
          <div className="max-w-7xl container pt-20 lg:text-4xl text-2xl helv font-semibold uppercase tracking-tighter mx-auto">
            We’re a market-leading creative studio headquartered in Austin, Texas. We are helping start-ups, challenger brands, and category leaders uncover the belief that makes their organization special and live it out through everything
            they do. We are a team of creative thinkers, strategists, and developers; delivering extraordinary results in ordinary timelines.
          </div>
          <div className="max-w-7xl container mx-auto">
            <div className=" mt-32">
              <div className="helv">
                DATA-DRIVEN APPROACH <br />
                FOR BUSINESS SUCCESS
              </div>
            </div>
          </div>
        </div>
        <div className="w-screen  pt-10">
          <div className="container hidden px-8 mx-auto grd lg:grid-cols-2 grid-cols-1 gap-20">
            <div className="col-span-1">
              <h3 className="text-4xl md:text-5xl lg:text-6xl font-bold helv relative">Management Team</h3>
              <p className="text-2xl leading-tight mt-8 lg:pr-20">
                Theorem Studio was founded by Earl Ameen and Adeel Farzand, themselves enterpreneurs having years of experience in the digital industry. They recognized that the digital market is still too complex. Some services, which are
                usually taken for granted in this sector, are not delivered at the same level as they should be. They built Theorem Studio to tackle this problem.
              </p>
            </div>
            <div className="col-span-1 grid md:grid-cols-2 grid-cols-1 gap-10">
              <div className="col-span-1 flex flex-col">
                <div className=" w-full overflow-hidden">
                  <img src={adeelImg} className="w-full h-full long opacity-100 object-cover" />
                </div>
                <hr className={`w-full h-0 border-t mt-4 mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
                <div className="flex items-center justify-between">
                  <p className="text-xl uppercase font-semibold">Adeel Farzand</p>
                  <p className="">Partner / CEO</p>
                </div>
                <hr className={`w-full h-0 border-t my-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
              </div>
              <div className="col-span-1 flex flex-col">
                <div className=" w-full overflow-hidden">
                  <img src={adeelImg} className="w-full h-full long opacity-100 object-cover" />
                </div>
                <hr className={`w-full h-0 border-t mt-4 mb-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
                <div className="flex items-center justify-between">
                  <p className="text-xl uppercase font-semibold">Earl Ameen</p>
                  <p className="">Partner / CGO</p>
                </div>
                <hr className={`w-full h-0 border-t my-2 ${theme === "dark" ? "border-white" : "border-black"}`} />
              </div>
            </div>
          </div>

          <div className="container mt-32 mb-40 mx-auto border-b border-primary-100"></div>

          <div className="container px-8 md:px-0 mx-auto grid lg:grid-cols-2 grid-cols-1 gap-20">
            <div className="col-span-1 ">
              <h3 className="text-4xl md:text-5xl lg:text-6xl font-bold helv relative">
                Why TheoremStudio<span className="absolute mb-2 text-3xl">&reg;</span>
              </h3>
            </div>
            <div className="col-span-1 grid md:grid-cols-2 grid-cols-1 gap-10">
              <div className="col-span-1 flex flex-col items-start justify-start">
                <div className="flex flex-col items-start justify-start">
                  <div className="flex w-32 h-32 border-2 border-black border-dashed items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 157 160">
                      <g transform="translate(-343 -149)">
                        <g transform="translate(343 149)" fill="none" stroke="#000" stroke-width="12">
                          <circle cx="61" cy="61" r="61" stroke="none" />
                          <circle cx="61" cy="61" r="55" fill="none" />
                        </g>
                        <g transform="translate(378 187)" fill="none" stroke="#000" stroke-width="12">
                          <circle cx="61" cy="61" r="61" stroke="none" />
                          <circle cx="61" cy="61" r="55" fill="none" />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <h5 className="text-4xl my-8">Shared Success</h5>
                  <p className="text-xl">We believe in mutual success, we succeed when you succeed. Like a valued partner we believe in long term relationship.</p>
                </div>

                <div className="flex mt-20 flex-col items-start justify-start">
                  <div className="flex w-32 h-32 border-2 border-black border-dashed items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 0 1 8.027 13H4.062a8.008 8.008 0 0 0 5.648 6.667zM10.03 13c.151 2.439.848 4.73 1.97 6.752A15.905 15.905 0 0 0 13.97 13h-3.94zm9.908 0h-3.965a17.9 17.9 0 0 1-1.683 6.667A8.008 8.008 0 0 0 19.938 13zM4.062 11h3.965A17.9 17.9 0 0 1 9.71 4.333 8.008 8.008 0 0 0 4.062 11zm5.969 0h3.938A15.905 15.905 0 0 0 12 4.248 15.905 15.905 0 0 0 10.03 11zm4.259-6.667A17.9 17.9 0 0 1 15.973 11h3.965a8.008 8.008 0 0 0-5.648-6.667z" />
                    </svg>
                  </div>
                  <h5 className="text-4xl my-8">Integrated Approach</h5>
                  <p className="text-xl">We work as a team of individuals who are well-versed in the field of technology and have a strong understanding of the industry.</p>
                </div>
              </div>
              <div className="col-span-1 flex flex-col items-start justify-start">
                <div className="flex flex-col items-start justify-start">
                  <div className="flex w-32 h-32 border-2 border-black border-dashed items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="40" height="40">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                    </svg>
                  </div>
                  <h5 className="text-4xl my-8">Intention Driven</h5>
                  <p className="text-xl">Our formulary process guides the evolution of design and development. We strategise with analytics and data to help you deliver better product.</p>
                </div>
                <div className="flex mt-20 flex-col items-start justify-start">
                  <div className="flex w-32 h-32 border-2 border-black border-dashed items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 197.998 202">
                      <g transform="translate(-505.001 -212)">
                        <g transform="translate(-11268 -17733)" fill="none">
                          <path d="M11773,18147v-122h76v-80h122v122h-76v80Z" stroke="none" />
                          <path
                            d="M 11883.001953125 18135 L 11883.001953125 18055 L 11958.9990234375 18055 L 11958.9990234375 17957 L 11860.9990234375 17957 L 11860.9990234375 18037 L 11785.0009765625 18037 L 11785.0009765625 18135 L 11883.001953125 18135 M 11895.001953125 18147 L 11773.0009765625 18147 L 11773.0009765625 18025 L 11848.9990234375 18025 L 11848.9990234375 17945 L 11970.9990234375 17945 L 11970.9990234375 18067 L 11895.001953125 18067 L 11895.001953125 18147 Z"
                            stroke="none"
                            fill="#000"
                          />
                        </g>
                        <line x2="70" transform="translate(607.5 241.5)" fill="none" stroke="#000" stroke-width="12" />
                        <line x1="70" transform="translate(672.5 235.5) rotate(90)" fill="none" stroke="#000" stroke-width="12" />
                      </g>
                    </svg>
                  </div>
                  <h5 className="text-4xl my-8">Growth Oriented</h5>
                  <p className="text-xl">Theorem delivers agile expertise to help you scale. From your first hire to fifth we meet your multidisciplinary design & development needs at every stage.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-32 mb-40 mx-auto border-b border-primary-100"></div>

          <div className="container px-8 md:px-0 mx-auto grid lg:grid-cols-2 grid-cols-1 gap-20">
            <div className="col-span-1 ">
              <h3 className="text-4xl md:text-5xl lg:text-6xl font-bold helv relative">What our Clients say</h3>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col items-start justify-start">
                <div className="flex text-3xl relative italic flex-col items-start justify-start">
                  <svg className="absolute top-12 left-5 lg:-left-2 transform -translate-x-8 -translate-y-24 lg:h-20 h-12 w-12 lg:w-20 text-org-100 opacity-75" stroke="currentColor" fill="none" viewBox="0 0 200 200" aria-hidden="true">
                    <path
                      strokeWidth={6}
                      d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                    />
                  </svg>
                  <p>We’ve been working with Theorem on multiple complex projects now, following an amazingly well-done first marketing website.</p>
                  <br />
                  <p>What really stands out for us is the multi-faceted expertise of the team and their dedication to ensuring our success by going above and beyond at every stage.</p>
                </div>
                <br />

                <div className="text-2xl font-bold mt-4 flex">
                  <p className="">M. Sadiq, CEO </p>&nbsp;&mdash;&nbsp;<p className=""> Escape Hotel</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container md:mt-32 my-20 md:mb-40 mx-auto border-b border-primary-100"></div>
          <div className="container px-8 md:pb-44 pb-20 md:px-0 mx-auto grid lg:grid-cols-3 grid-cols-1 gap-20">
            <div className="col-span-2 ">
              <h3 className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-bold helv relative">We believe that in a world that turns you into a number, we are able to use technology to deliver to you an unparalleled service.</h3>
            </div>
            <div className="col-span-1 grid grid-cols-2 gap-10"></div>
          </div>
        </div>
        <Marquee cont="CONTACT US" />
      </div>
    </>
  );
}
